/**
 * Singleton class
 */
export default class Singleton {
  static _instance = null

  static get instance () {
    if(!this._instance)
      this._instance = new this()

    return this._instance
  }

  constructor() {

  }
}