import ReactComponent from "../app/ReactComponent";
import { Layout, Row, Col, Image, Space, List} from 'antd'
import React from 'react'
import Query from "../app/Query";
import {Link} from "react-router-dom";
import {getStoreNameByStoreId} from "../services/stores";
import {API_RESERVATION_STATE} from "../helpers/misc";
import { LeftOutlined } from '@ant-design/icons'
import {CancelRewardsButton} from "../components/buttons/CancelRewardsButton"
import App from "../app/App"

export class RewardsHistoryPage extends ReactComponent {
  state = {
    loading_page: false,
    stores: [],
    product_reservations: [],
    overview: {
      items: [],
      sorter: {
        sort_field: 'points_cost',
        sort_direction: 'asc',
        sort_fields: [
          'points_cost',
          'name'
        ]
      },
      pagination: {
        defaultCurrent: 1,
        total: 0,
        defaultPageSize: 5,
        current: 1,
      }
    }
  }

  componentDidMount() {
    this.pushPageAnalytics("Přehled objednávek - Moje Amber")

    void this.getPageData()
  }

  getPageData = async () => {

    const stores = await this.$app.$store('common').getStores()

    await this.updateState({
      stores: stores
    })

    void this.getRewards()


  }

  getRewards = async (offset = this.state.overview.pagination.current, sort_direction = this.state.overview.sorter.sort_direction) => {
    void this.updateState({
      loading_page: true
    })
    const query = new Query('product-reservations')

    query.add('count', this.state.overview.pagination.defaultPageSize)
    query.add('offset', (offset - 1) * this.state.overview.pagination.defaultPageSize)
    query.add('sort_field', 'last_change')
    query.add('sort_direction', 'desc')


    const {product_reservations = [], total_items = 0} = await this.$api({
      action: 'carecloud_api',
      route: query.route
    })

    // let res = await this.$api({route: `stores/${item.store_id}`})

    const asyncProductData = async (product_id, reservation_index) => {
      let res = await this.$api({route: `products/${product_id}`})

      product_reservations[reservation_index].product = res
    }


    for (const item of product_reservations) {
      const index = product_reservations.indexOf(item);
      item.store_name = getStoreNameByStoreId(this.state.stores, item.store_id)
      item.text_state = API_RESERVATION_STATE[item.actual_state]

      if (item.hasOwnProperty('product_reservation_items') &&
        Array.isArray(item.product_reservation_items)) {

        for (const product of item.product_reservation_items) {
          await asyncProductData(product.reservable_product_id, index)
        }

      }

    }

    await this.updateState({
      loading_page: false,
      product_reservations: product_reservations,
      overview: {
        ...this.state.overview,
        pagination: {
          ...this.state.overview.pagination,
          total: total_items,
          current: offset
        }
      }
    })

    this.scrollToTop()

  }

  async cancelReservation (item) {

    void this.updateState({loading_page: true})

    try {

      const {product_reservation_id} = item

      await this.$api({
        action: 'carecloud_api',
        route: `product-reservations/${product_reservation_id}/actions/cancel-reservation`,
        method: 'POST'
      })

      await this.getRewards()

      App.$toast("Objednávka zrušena.")

    } catch (error) {

      console.log(error)

    } finally {

      void this.updateState({loading_page: false})

    }
  }

  render() {
    return (
      <Layout tagName={"main"} className="male rewards">
        <Row align="center">
          <Col xs={24} md={24} xl={20}>
            <Space className="rewards-history-title" size={[0,0]}>
              <Link to={"/rewards"}><LeftOutlined/><span className="back-to-rewards">Zpět na Amber konto</span></Link>
              <h1>Moje objednávky</h1>
            </Space>
            <h4>Jaké odměny jste již získali?</h4>
          </Col>
          <Col xs={24} md={24} xl={20}>
            <Row className="rewards-history-item-wrapper">
              <Col xs={24} sm={24} md={9} align="left" className="advertising">
                {/*<p>Reklama</p>*/}
              </Col>
              <Col xs={24} sm={24} md={15}>
                <List
                  loading={this.state.loading_page}
                  pagination={{...this.state.overview.pagination, onChange: this.getRewards, showSizeChanger: false}}
                  itemLayout="horizontal"
                  style={{maxWidth: "480px"}}
                  dataSource={this.state.product_reservations} renderItem={(item) => (
                  <List.Item>
                    <Space className="rewards-history-item" size={[0,0]} wrap>
                      {item.product?.image_urls?.length > 0 &&
                        <Image preview={false} src={item.product.image_urls[0]} style={{maxHeight: 68, maxWidth: 68, width: "auto"}} className=""/>
                      }
                      <table align={"left"}>
                        <tbody>
                        <tr>
                          <td colSpan="2"><strong>{item.product?.name || ""}</strong></td>
                        </tr>
                        <tr>
                          <td>Pobočka</td>
                          <td>{item.store_name}</td>
                        </tr>
                        <tr>
                          <td>Stav</td>
                          <td><strong>{item.text_state}</strong></td>
                        </tr>
                        </tbody>
                      </table>

                      <CancelRewardsButton
                        disabled={item.actual_state !== 2}
                        title="Zrušit objednávku"
                        description="Opravdu chcete objednávku zrušit?"
                        item={item}
                        onConfirm={(...args) => {
                          this.cancelReservation(...args)
                        }}/>

                    </Space>
                  </List.Item>
                )}/>
              </Col>
            </Row>
        </Col>
      </Row>
  </Layout>
  )
  }
}